import { useCallback } from "react";

import type { IUserFullPublic } from "../../models/user.model";
import type { AccessPermission, Permission } from "../../security/permissions";
import { useAuthContext } from "../auth/hooks";
import { useOrganization } from "../organization/context/organization-provider";

// ----------------------------------------------------------------------

function hasRole(access: AccessPermission, userRoles: Permission[]): boolean {
  if (typeof access === "object") {
    if ("some" in access) {
      return access.some.map((a) => hasRole(a, userRoles)).some((a) => a);
    }

    if ("every" in access) {
      return access.every.map((a) => hasRole(a, userRoles)).every((a) => a);
    }
    return false;
  }
  return userRoles.includes(access);
}

function hasAccessTo(user: IUserFullPublic, organization_id: string, access: AccessPermission): boolean {
  const userOrganization = user.organizations.find((o) => o._id.toString() === organization_id.toString());
  if (!userOrganization) {
    return false;
  }
  return hasRole(access, userOrganization.roles);
}

export interface useBooleanReturnType {
  hasOrganizationRole: (access: AccessPermission, organization_id?: string) => boolean;
}

export function useRoleAuthorization(): useBooleanReturnType {
  const { user } = useAuthContext();
  const { organization } = useOrganization();

  const hasOrganizationRole = useCallback(
    (access: AccessPermission, organization_id?: string) => {
      if (!organization && !organization_id) {
        return false;
      }
      if (!user) {
        return false;
      }
      const orgaId = organization_id ? organization_id.toString() : organization?._id.toString() || "";
      return hasAccessTo(user, orgaId, access);
    },
    [user, organization]
  );

  return {
    hasOrganizationRole,
  };
}
