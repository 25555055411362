import Container from "@mui/material/Container";
import type { SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { m } from "framer-motion";
import { useRouter } from "next/navigation";
import React, { useCallback, useEffect, useState } from "react";
import type { AccessPermission } from "../../../security/permissions";
import { ForbiddenIllustration } from "../../assets/illustrations";
import { MotionContainer, varBounce } from "../../components/animate";
import { useRoleAuthorization } from "../../hooks/use-role-authorization";

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  shouldRedirect?: boolean;
  access: AccessPermission;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};
export default function RoleBasedGuard({
  hasContent,
  shouldRedirect = true,
  access,
  children,
  sx
}: RoleBasedGuardProp) {
  const router = useRouter();
  const [checked, setChecked] = useState("INIT"); // "INIT" | "DENIED" | "AUTHORIZED"

  const {
    hasOrganizationRole
  } = useRoleAuthorization();
  const check = useCallback(() => {
    const authorized = hasOrganizationRole(access);
    if (!authorized && shouldRedirect) {
      router.replace("/403");
    } else {
      setChecked(authorized ? "AUTHORIZED" : "DENIED");
    }
  }, [router, hasOrganizationRole, access]);
  useEffect(() => {
    check();
  }, []);
  if (checked === "INIT") {
    return null;
  }
  if (checked === "DENIED") {
    return hasContent ? <Container component={MotionContainer} sx={{
      textAlign: "center",
      ...sx
    }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{
          mb: 2
        }}>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{
          color: "text.secondary"
        }}>You do not have permission to access this page</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{
          height: 260,
          my: {
            xs: 5,
            sm: 10
          }
        }} />
        </m.div>
      </Container> : null;
  }
  return <> {children} </>;
}