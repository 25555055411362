import React, { useCallback, useEffect, useState } from "react";
import { SplashScreen } from "../../components/loading-screen";
import { usePathname, useRouter } from "../../routes/hooks";
import { PAGES } from "../../routes/site-paths";
import { useAuthContext } from "../hooks";

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: PAGES.auth.login().path
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
export default function AuthGuard({
  children
}: Props) {
  const {
    loading
  } = useAuthContext();
  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({
  children
}: Props) {
  const router = useRouter();
  const pathname = usePathname();
  const {
    authenticated,
    user,
    method
  } = useAuthContext();
  const [checked, setChecked] = useState(false);
  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname
      }).toString();
      const loginPath = loginPaths[method];
      const href = `${loginPath}?${searchParams}`;
      router.replace(href);
    } else if (user?.account_status !== "ACTIVE" && !/^\/auth\/setup\//g.test(pathname)) {
      if (user?.account_status === "COMPLETE_REGISTERING") {
        router.push(PAGES.auth.setup.password().path);
      } else if (user?.account_status === "COMPLETE_REGISTERING_STEP2") {
        router.push(PAGES.auth.setup["2fa"].mode.path);
      } else {
        router.push("/not-found");
      }
    } else {
      setChecked(true);
    }
  }, [authenticated, method, router, user, pathname]);
  useEffect(() => {
    check();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!checked) {
    return null;
  }
  return <>{children}</>;
}