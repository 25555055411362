"use client";

import { usePathname } from "next/navigation";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import type { IOrganization } from "../../../models/organization.model";
import type { ActionMapType } from "../../auth/types";
import { apiGet } from "../../utils/api.utils";
interface IOrganizationContext {
  organization: IOrganization | null;
  loading: boolean;
  organizationId: string;
  setOrganization: (organization_id: string) => void;
  refetchOrganization: () => void;
}
export const OrganizationContext = createContext<IOrganizationContext>({
  organization: null,
  loading: true,
  organizationId: "",
  setOrganization: () => {},
  refetchOrganization: () => {}
});

// ----------------------------------------------------------------------

export type OrganizationStateType = {
  loading: boolean;
  organization: IOrganization | null;
};
enum Types {
  FETCHING = "FETCHING",
  SET = "SET",
}
type Payload = {
  [Types.FETCHING]: undefined;
  [Types.SET]: {
    organization: IOrganization | null;
  };
};
type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: OrganizationStateType = {
  organization: null,
  loading: true
};
const reducer = (state: OrganizationStateType, action: ActionsType) => {
  if (action.type === Types.FETCHING) {
    return {
      ...state,
      loading: true
    };
  }
  if (action.type === Types.SET) {
    return {
      ...state,
      loading: false,
      organization: action.payload.organization
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
export function OrganizationProvider({
  children
}: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const pathname = usePathname();
  const organizationId = useMemo(() => {
    const urlSegments = pathname?.split("/").filter(Boolean);
    if (urlSegments) {
      if (urlSegments.length > 1) {
        return urlSegments[1];
      }
    }
    return "";
  }, [pathname]);

  // SET
  const setOrganization = useCallback(async (organization_id: string) => {
    try {
      dispatch({
        type: Types.FETCHING
      });
      const organization = await apiGet("/organization/:id", {
        params: {
          id: organization_id
        }
      });
      dispatch({
        type: Types.SET,
        payload: {
          organization: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(organization as any)
          }
        }
      });
    } catch (_error) {
      dispatch({
        type: Types.SET,
        payload: {
          organization: null
        }
      });
    }
  }, []);
  const refetchOrganization = useCallback(async () => {
    if (!organizationId) return;
    setOrganization(organizationId);
  }, [setOrganization, organizationId]);
  useEffect(() => {
    if (!organizationId) return;
    setOrganization(organizationId);
  }, [setOrganization, organizationId]);

  // ----------------------------------------------------------------------

  const memoizedValue = useMemo(() => ({
    organization: state.organization,
    loading: state.loading,
    organizationId,
    //
    setOrganization,
    refetchOrganization
  }), [setOrganization, refetchOrganization, state.organization, state.loading, organizationId]);
  return <OrganizationContext.Provider value={memoizedValue} data-sentry-element="unknown" data-sentry-component="OrganizationProvider" data-sentry-source-file="organization-provider.tsx">{children}</OrganizationContext.Provider>;
}
export const useOrganization = () => useContext(OrganizationContext);