import React, { useCallback, useEffect } from "react";
import { SplashScreen } from "../../components/loading-screen";
import { useRouter, useSearchParams } from "../../routes/hooks";
import { PAGES, ROOTS } from "../../routes/site-paths";
import { useAuthContext } from "../hooks";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
export default function GuestGuard({
  children
}: Props) {
  const {
    loading
  } = useAuthContext();
  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({
  children
}: Props) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const {
    authenticated,
    mustVerify
  } = useAuthContext();
  const returnTo = searchParams.get("returnTo");
  const check = useCallback(() => {
    if (authenticated) {
      if (mustVerify) {
        const params = returnTo ? `?returnTo=${returnTo}` : "";
        router.replace(`${PAGES.auth.verify.path}${params}`);
      } else {
        router.replace(returnTo || ROOTS.DASHBOARD);
      }
    }
  }, [authenticated, returnTo, mustVerify, router]);
  useEffect(() => {
    check();
  }, [check]);
  return <>{children}</>;
}