"use client";

import { redirect } from "next/navigation";
import { AuthGuard } from "shared/ui/auth/guard";
import { useAuthContext } from "shared/ui/auth/hooks";
import { PAGES } from "shared/ui/routes/site-paths";

// ----------------------------------------------------------------------

export default function HomePage() {
  const {
    user
  } = useAuthContext();
  if (!user) return <AuthGuard>
        <></>
      </AuthGuard>;
  redirect(PAGES.dashboard(user?.organization_id).root().path);
}